



































































import { Component, Vue, Ref } from "vue-property-decorator";
import { passwordlost } from "@/api/patients";
import { Message } from "element-ui";

@Component({
  name: "Register",
})
export default class extends Vue {
  @Ref("form") readonly elForm!: HTMLFormElement;

  private email = "";
  private loading = false;
  private formValidated = false;

  private async onLostPassword() {
    // Vérifier la validité des champs
    if (this.elForm.checkValidity() === false) {
      this.formValidated = true;
    } else {
      // LostPassword
      this.loading = true;
      try {
        const { data } = await passwordlost(this.email);
        Message({
          message: "Demande réussie. Vous allez recevoir un mail vous permettant de changer de mot de passe.",
          type: "success",
          duration: 5 * 1000,
          onClose: this.onRedirectLogin,
        });
      } finally {
        this.loading = false;
      }
    }
  }

  private onRedirectLogin() {
    this.$router.push(`/login`);
  }
}
